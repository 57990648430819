<template>
  <section style="transition: all .5s ease;">
    <validation-observer ref="formRules" v-slot="{ invalid }">
      <div class="modal d-flex justify-content-end">
        <div class="bg-white p-2 w-50 d-flex flex-column">
          <div class="d-flex align-items-center border-b pb-1 mb-1">
            <strong class="my-0 text-dark text-10">Tambah Potongan Biaya</strong>
            <span class="ml-auto k-close-circle cursor-pointer text-10" @click="$emit('close')"></span>
          </div>
          <b-overlay
            variant="light"
            :show="loading"
            spinner-variant="primary"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
              <b-row v-for="(discount, i) in discounts" :key="i">
                <b-col
                  md="5"
                  class="pr-0"
                >
                  <b-form-group
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Jumlah Talent
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Jumlah Talent"
                      rules="integer|required"
                    >
                      <b-form-input
                        type="number"
                        v-model.number="discount.minimum_total_talent"
                        :state="
                          errors.length > 0 ? false : null
                        "
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="5"
                  class="pr-0"
                >
                  <b-form-group
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Biaya Potongan
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Biaya Potongan"
                      rules="required|min_value:10"
                    >
                      <money
                        v-model="discount.admin_fee_discount_value"
                        v-bind="money"
                        class="form-control"
                        :state="
                          errors.length > 0 ? false : null
                        "
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="2"
                  class="d-flex justify-content-center align-items-center mt-[12px]"
                >
                  <BButton variant="outline-danger" size="sm"
                    @click="selectItem(i)"
                    :disabled="discounts.length === 1"
                  >
                    <span class="k-trash color-warning h-100 font-bold text-10 align-middle"></span>
                  </BButton>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-end mt-auto">
                <button
                  class="button-secondary w-[200px] py-[8px]"
                  @click.prevent="addMoreDiscount()"
                >
                  Tambah Biaya
                </button>
              </div>
          </b-overlay>
          <div class="d-flex justify-content-end mt-auto">
            <button
              class="button-primary w-[200px] py-[8px]"
              :disabled="invalid"
              @click.prevent="submit()"
            >
              Tambahkan
            </button>
          </div>
        </div>
      </div>
      <div class="modal-backdrop"></div>
    </validation-observer>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { alertError, alertSuccess } from '@toast'
import {
  required,
  integer,
} from '@validations'

export default {
  props: {
    discountExist: {
      type: Array,
      default: null,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      alertError,
      alertSuccess,
      required,
      integer,
      discounts: [
        {
          minimum_total_talent: '',
          admin_fee_discount_value: '',
        },
      ],
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
      },
    }
  },
  beforeMount() {
    if (this.discountExist.length) {
      this.discounts = [...this.discountExist]
    }
  },
  methods: {
    async submit() {
      this.$emit('addTalent', this.discounts)
      this.$emit('close')
    },
    addMoreDiscount() {
      this.discounts.push({
        minimum_total_talent: '',
        admin_fee_discount_value: '',
      })
    },
    selectItem(index) {
      this.discounts.splice(index, 1)
    },
  },
}
</script>
